import { createStore } from 'vuex'

const notificationStore = createStore({
  state: {
    toasts: []
  },
  mutations: {
    addErrorNotification(state, message) {
      var toast = {
        message: message,
        isError: true
      };
      state.toasts.push(toast);
      setTimeout(() => {
        const index = state.toasts.findIndex((searchToast) => searchToast.message === toast.message); // find toast
        state.toasts.splice(index, 1); // remove toast from array
      }, 5000);
    },
    addSuccessNotification(state, message) {
      var toast = {
        message: message,
        isError: false
      };
      state.toasts.push(toast);
      setTimeout(() => {
        const index = state.toasts.findIndex((searchToast) => searchToast.message === toast.message); // find toast
        state.toasts.splice(index, 1); // remove toast from array
      }, 5000);
    }
  },
  actions: {
  },
  modules: {
  }
})

export default notificationStore;