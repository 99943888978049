<!-- eslint-disable no-unused-vars -->
<template>
    <ToastContainer class="position-fixed end-0 bottom-0 p-3" v-if="toasts.length">
        <div v-for="(toast, index) of toasts" :key="index">
            <Toast style="margin-top:10px;" :class="toast.isError == true ? 'fade text-bg-danger border-danger show' : 'fade text-bg-success border-success show'">
                <ToastBody>
                    {{ toast.message }}
                </ToastBody>
            </Toast>
        </div>
    </ToastContainer>
</template>
  
<script>
import ToastContainer from '@/components/uielements/toast/ToastContainer.vue';
import Toast from '@/components/uielements/toast/Toast.vue';
import ToastBody from '@/components/uielements/toast/ToastBody.vue'; 
  
export default {
    name: 'GlobalFeedbackToast',
    components: {
        Toast,
        ToastBody,
        ToastContainer,
    },
    computed: {
        toasts() {
            return this.$store.state.toasts;
        }
    }
}
</script>