<template>
  <router-view></router-view>
  <GlobalFeedbackToast/>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style>
.vue__time-picker .dropdown ul{
    scrollbar-width: thin;
}

.coins-large::before{
    font-size: 30px;
    top: 7px;
    position: relative;
}

.coins-small::before{
    font-size: 20px;
    position: relative;
}
.table-responsive{
  min-height:400px;
}
.vueticol-wrapper {
  width: 43px !important;
  height: 43px !important;
  border: 0.0625rem solid #d2d4e4 !important; 
  border-radius: 0.375rem !important;
}

.vueticol-container .vueticol-wrapper:focus {
  border: 0.0625rem solid rgba(95, 56, 249, 0.65) !important; 
  outline: 0;
  box-shadow: 0 0 5px 0px rgba(95, 56, 249, 0.2);
}

.multiselect__select::before{
  display:none;
}

.multiselect__single{
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-left:0px;
}

.multiselect__placeholder {
  color: #adadad;
  display: inline-block;
  margin-bottom: 10px;
  padding-top: 3px;
  padding-left:0px;
}




.multiselect__input{
  font-size:0.875rem;
  padding:3px 0px;
}

.multiselect__option--highlight{
  background:#EEEEEE;
  color:#000000;
}

.multiselect__option--highlight::after {
  background:#EEEEEE;
  color:#000000;
  content: '';
}

.multiselect__option--selected::after {
  background:#EEEEEE;
  color:#000000;
  content: '';
}

.multiselect__option--selected.multiselect__option--highlight {
  background:#EEEEEE;
  color:#000000;
}

.multiselect__option--selected.multiselect__option--highlight::after {
  background: #ff6a6a;
  content: '';
  color: #fff;
}

.vueticol-dropdown
{
    z-index:1;
}

@media (min-width: 576px)
{
    .data-table-top .form-control {
        width:300px;
        float:left;
    }

    .data-table-top .form-select {
        width:unset;
        float:left;
        margin-left:10px;
    }
}

@media (max-width: 576px)
{
    .data-table-top .form-control {
        margin-bottom: 20px;
    }
}

/*Mobile Time Entries*/
.mobileTimeEntryCard{
	margin:5px;
	font-size: 0.7rem;
}

.mobileTimeEntryCard .entryHeader{
	font-weight: bold;
}

.mobileTimeEntryCard .entryDetail{
	word-break: break-word;
}

.mobileTimeEntryCard .headerRow{
	padding-bottom:10px;
    font-weight: bold;
}

.mobileTimeEntryCard .footerRow{
	margin-top:15px;
    height: 20px;
}

.mobileTimeEntryCard .footerRow .rightColumn{
	font-weight: bold;
	text-align:right;
}
</style>